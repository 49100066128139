/* HELPERS */
.d-block {
    display: block !important;
}
.no-margin-bottom { margin-bottom: 0 !important; }

/* CUSTOM STYLES */
body {
    &.login-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh !important;
        .login-box {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 300px;
            margin: 0;
            .login-logo, .login-box-body {
                width: 100%;
            }
        }
        footer {
            &.main-footer {
                position: fixed;
                width: 100%;
                bottom: 0;
                margin-left: 0;
            }
        }
    }
    label.error{
        display: none !important;
    }
    input.error{
        border-color: #dd4b39;
    }
    .navbar-nav>.user-menu>.dropdown-menu>li.user-header{
        height: auto;
        min-height: 175px;
    }
    .input-disabled {
        min-width: 290px;
    }
}

header.main-header {
    .logo {
        .logo-lg {
            img {
                height: 30px;
                display: block;
                margin: 11px auto 0 auto;
            }
        }
        .logo-mini {
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}